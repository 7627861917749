import { customButtonTheme } from './components/button'
import { customCardTheme } from './components/card'
import { customClipboardTheme } from './components/clipboard'
import { customTextInputTheme } from './components/forms/textInput'
import { customLabelTheme } from './components/forms/label'
import { customCheckboxTheme } from './components/forms/checkbox'
import { customTooltipTheme } from './components/tooltip'

const relayTheme = {
  card: customCardTheme,
  button: customButtonTheme,
  clipboard: customClipboardTheme,
  textInput: customTextInputTheme,
  label: customLabelTheme,
  checkbox: customCheckboxTheme,
  tooltip: customTooltipTheme
}

export { relayTheme }
