import type { CustomFlowbiteTheme } from 'flowbite-react'

// Part of Forms - Find all default options at https://flowbite-react.com/docs/components/forms#text-input-theme
export const customTextInputTheme: CustomFlowbiteTheme['textInput'] = {
  field: {
    input: {
      base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-gray-500',
      sizes: {
        md: 'p-3 text-sm leading-normal',
        xs: 'px-2 py-0.5 text-xs'
      }
    }
  }
}
