import type { CustomFlowbiteTheme } from 'flowbite-react'

// Find all default options at https://flowbite-react.com/docs/components/clipboard#theme
export const customClipboardTheme: CustomFlowbiteTheme['clipboard'] = {
  button: {
    // xs: button actual copy of button classes from devtools
    base: 'group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none ring-primary-800 ring-offset-2 border border-gray-200 bg-gray-200 text-gray-900 focus:text-gray-900 focus:ring-2 enabled:hover:bg-gray-350 enabled:hover:text-gray-900 dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white rounded-lg',
    label: 'px-2 py-1 text-xs'
  }
}
