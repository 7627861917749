import { ReactElement, PropsWithChildren, useState } from 'react'
import classNames from 'classnames'
import * as Sentry from '@sentry/react'
import { Toast } from 'flowbite-react'
import { AlertContext, AlertContextType } from './AlertErrorsContext'
import Error500Page from './Error500Page'
import { ExclamationCircle } from 'flowbite-react-icons/outline'
export type Severity = 'WARNING' | 'ERROR' | 'FATAL'

export default function ErrorsContextProvider(
  props: PropsWithChildren
): ReactElement {
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [severity, setSeverity] = useState<Severity | undefined>(undefined)

  const fatal = (message: string) => {
    setMessage(message)
    setSeverity('FATAL')
    Sentry.captureException(new Error(message))
  }

  const error = (message: string) => {
    setMessage(message)
    setSeverity('ERROR')
    Sentry.captureMessage(message)
  }

  const warning = (message: string) => {
    setMessage(message)
    setSeverity('WARNING')
  }

  const silent = (message: string) => {
    Sentry.captureMessage(message)
  }

  const catchFatal = (error: Error) => {
    setMessage(error.toString())
    setSeverity('FATAL')
    Sentry.captureException(error)
  }

  const catchError = (error: Error) => {
    setMessage(error.toString())
    setSeverity('ERROR')
    Sentry.captureException(error)
  }

  const catchWarning = (error: Error) => {
    setMessage(error.toString())
    setSeverity('WARNING')
    Sentry.captureException(error)
  }

  const catchSilent = (error: Error) => {
    Sentry.captureException(error)
  }

  const errorContext: AlertContextType = {
    fatal,
    error,
    warning,
    silent,
    catchFatal,
    catchError,
    catchWarning,
    catchSilent
  }

  if (severity === 'FATAL') {
    return <Error500Page message={message} />
  }

  return (
    <Sentry.ErrorBoundary fallback={<Error500Page />}>
      <AlertContext.Provider value={errorContext}>
        {props.children}
        {message && (
          <Toast
            theme={{
              toggle: {
                base: 'w-max-20 -m-1.5 ml-auto inline-flex rounded-lg bg-white p-1.5 text-gray-400 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white'
              }
            }}
            className={classNames(
              'fixed right-4 top-4 z-20 items-start border',
              severity === 'ERROR'
                ? 'border-red-500 bg-red-100 text-red-700 shadow dark:bg-red-800 dark:text-red-200'
                : 'border-yellow-500 bg-yellow-100 text-yellow-700 shadow dark:bg-yellow-800 dark:text-yellow-200'
            )}
          >
            <div className="flex flex-row items-center gap-2">
              {severity === 'ERROR' && <ExclamationCircle size={24} />}
              <div className="ml-3 text-sm font-normal">{message}</div>
            </div>
            <Toast.Toggle
              className="bg-transparent"
              onDismiss={() => setMessage(undefined)}
            />
          </Toast>
        )}
      </AlertContext.Provider>
    </Sentry.ErrorBoundary>
  )
}
