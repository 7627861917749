import type { ReactElement } from 'react'
import classNames from 'classnames'
import {
  Chart,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { segmentIcon, sinusIcon } from '../assets'
Chart.register(LineElement, CategoryScale, LinearScale, PointElement)

export type StatsPanelProps = {
  periodLabel: string
  totalCount: number
  netChange: number
  entered: number
  left: number
  chartData: number[]
  className?: string
}

export function sampleStats() {
  return {
    periodLabel: 'last 30 days',
    totalCount: 1038,
    netChange: 285,
    entered: 326,
    left: 41,
    chartData: [0, 20, 20, 60, 60, 120, 40, 180, 120, 125, 105, 110, 170]
  }
}
export default function StatsPanel(props: StatsPanelProps): ReactElement {
  const chartData = {
    labels: props.chartData,
    datasets: [
      {
        data: props.chartData,
        borderColor: 'blue',
        tension: 0.4,
        pointRadius: 0
      }
    ]
  }
  return (
    <section
      className={classNames(
        'flex flex-col divide-y divide-gray-200',
        props.className
      )}
    >
      <article className="p-4">
        <div className="flex flex-row items-center gap-2 text-sm font-semibold text-gray-900">
          <img src={segmentIcon} className="w-5" />
          <div className="grow">People</div>
          <div className="text-gray-400">{props.periodLabel}</div>
        </div>
        <div className="mt-4 flex w-full flex-row justify-between">
          <div className="flex flex-col text-sm font-semibold text-gray-700">
            <div className="text-xs">Total count</div>
            <div>{props.totalCount.toFixed(2)}</div>
            <div className="mt-2 text-xs">Entered</div>
            <div>{props.entered}</div>
          </div>
          <div className="flex flex-col text-sm font-semibold text-gray-700">
            <div className="text-xs">Net change</div>
            <div>{props.netChange.toFixed(2)}</div>
            <div className="mt-2 text-xs">Left</div>
            <div>{props.left}</div>
          </div>
        </div>
      </article>
      <article className="p-4">
        <div className="flex flex-row items-center gap-2 text-sm font-semibold text-gray-900">
          <img src={sinusIcon} className="w-5" />
          <div className="grow">Attributed</div>
          <div className="text-gray-400">{props.periodLabel}</div>
        </div>
        <div className="mt-4">
          {
            <Line
              data={chartData}
              options={{
                layout: {
                  padding: {
                    top: 20,
                    bottom: 20
                  }
                },
                scales: {
                  y: {
                    display: false
                  },
                  x: {
                    display: false
                  }
                }
              }}
            />
          }
        </div>
      </article>
    </section>
  )
}
