import {
  createContext,
  useContext,
} from 'react'
import type { User, Credentials } from '../types'

export type AuthContextType = {
  user?: User
  isEmbedded: boolean
  login: (user: User, credentials?: Credentials) => void
  logout: () => void
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function useAuth(): AuthContextType | undefined {
  return useContext(AuthContext)
}
