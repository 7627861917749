import { createContext, useContext } from 'react'

export type AlertContextType = {
  fatal: (message: string) => void
  error: (message: string) => void
  warning: (message: string) => void
  silent: (message: string) => void
  catchFatal: (error: Error) => void
  catchError: (error: Error) => void
  catchWarning: (error: Error) => void
  catchSilent: (error: Error) => void
}

const dummyFn = (_message: string) => {}
const dummyCb = (_error: Error) => {}

const placeholder: AlertContextType = {
  fatal: dummyFn,
  error: dummyFn,
  warning: dummyFn,
  silent: dummyFn,
  catchFatal: dummyCb,
  catchError: dummyCb,
  catchWarning: dummyCb,
  catchSilent: dummyCb
}

export const AlertContext = createContext<AlertContextType>(placeholder)

export function useAlert(): AlertContextType {
  return useContext(AlertContext)
}
