import { ReactElement, PropsWithChildren, useState, useEffect } from 'react'
import { AuthContext } from './AuthContext'
import type { User } from '../types'

import { getUser as storedUserGetter, logout as performLogout } from './utils'

export function AuthContextProvider(props: PropsWithChildren): ReactElement {
  const [user, setUser] = useState<User | undefined>(undefined)
  const isEmbedded =
    window.location.href.includes('embedded=yes') ||
    Boolean(sessionStorage.getItem('embedded'))
  if (isEmbedded) {
    sessionStorage.setItem('embedded', 'yes')
  }

  useEffect(() => {
    setUser(storedUserGetter())
  }, [])

  const login = (user: User) => {
    setUser(user)
  }
  const logout = () => {
    setUser(undefined)
    performLogout()
  }

  const context = {
    user,
    isEmbedded,
    login,
    logout
  }

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  )
}
