import '@repo/ui/composer'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Flowbite } from 'flowbite-react'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as Sentry from '@sentry/react'
import { relayTheme } from '@repo/ui/theme'
import { AuthContextProvider } from './auth/AuthContextProvider.tsx'
import { AlertContextProvider } from './alert/index.ts'
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'
import './index.css'
import configureRoutes from './routes.tsx'
extend(relativeTime)

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(configureRoutes())
)

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Flowbite theme={{ theme: relayTheme }}>
      <AlertContextProvider>
        <AuthContextProvider>
          <RouterProvider router={router} />
        </AuthContextProvider>
      </AlertContextProvider>
    </Flowbite>
  </StrictMode>
)
