import type { CustomFlowbiteTheme } from 'flowbite-react'

export const customTooltipTheme: CustomFlowbiteTheme['tooltip'] = {
  arrow: {
    style: { dark: 'bg-[#2B3546] dark:bg-gray-700' }
  },
  style: {
    dark: 'bg-[#2B3546] text-white dark:bg-gray-700'
  }
}
