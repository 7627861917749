import axios from 'axios'

import createAuthRefreshInterceptor from 'axios-auth-refresh'

export const baseURL: string = import.meta.env.VITE_API_BASE_URL

export const REFRESH_TOKEN_KEY = 'relay-refresh'
export const ACCESS_TOKEN_KEY = 'relay-access'
export const WORKSPACE_ID_KEY = 'relay-workspace'
export const AUTH_METHOD_KEY = 'relay-method'

function getAccessToken(): string | null {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY)
}

function getRefreshToken(): string | null {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY)
}

export function getWorkspaceId(): string | null {
  return sessionStorage.getItem(WORKSPACE_ID_KEY)
}

function getAuthMethod(): string | null {
  return sessionStorage.getItem(AUTH_METHOD_KEY)
}

type BackendRefreshResponse = {
  data: {
    authentication_token: string
  }
}

export async function performRefreshRequest(
  failedRequest: object
): Promise<void> {
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    return Promise.reject()
  }
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  try {
    const response = await axios.post<BackendRefreshResponse>(
      `${baseURL}/auth/embedded/user_sessions/refresh`,
      { refresh_token: refreshToken }
    )
    const accessToken = response.data.data.authentication_token
    sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
    // @ts-ignore: Axios refresh library isn't well typed yet
    failedRequest.response.config.headers['Authorization'] =
      `Bearer ${accessToken}`
    return Promise.resolve()
  } catch {
    sessionStorage.removeItem(REFRESH_TOKEN_KEY)
    return Promise.reject()
  }
}

const api = axios.create({
  baseURL,
  timeout: 3000
})

createAuthRefreshInterceptor(api, performRefreshRequest)
api.interceptors.request.use(
  (request) => {
    const accessToken = getAccessToken()
    const workspaceId = getWorkspaceId()
    if (accessToken) {
      request.headers['Authorization'] = `Bearer ${accessToken}`
      request.headers['X-Auth-Type'] = getAuthMethod()
    }
    if (workspaceId) {
      request.headers['X-Workspace-Id'] = workspaceId
    }
    return request
  },
  (error) => Promise.reject(error)
)

export default api
