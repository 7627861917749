import axios from 'axios'
import {
  baseURL,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  WORKSPACE_ID_KEY,
  AUTH_METHOD_KEY
} from '../services/api'
import type { User } from '../types'
const STORED_USER_KEY = 'relay-user'

export function setAccessToken(token: string): void {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export function setRefreshToken(token?: string): void {
  token
    ? sessionStorage.setItem(REFRESH_TOKEN_KEY, token)
    : sessionStorage.removeItem(REFRESH_TOKEN_KEY)
}

export function setWorkspaceId(workspaceId?: string): void {
  workspaceId
    ? sessionStorage.setItem(WORKSPACE_ID_KEY, workspaceId)
    : sessionStorage.removeItem(WORKSPACE_ID_KEY)
}

export function setAuthMethod(method: 'embedded' | 'basic'): void {
  sessionStorage.setItem(AUTH_METHOD_KEY, method)
}

export function setUser(user: User): void {
  sessionStorage.setItem(STORED_USER_KEY, JSON.stringify(user))
}

export function getUser(): User | undefined {
  return sessionStorage.getItem(STORED_USER_KEY)
    ? (JSON.parse(sessionStorage.getItem(STORED_USER_KEY)!) as User)
    : undefined
}

export type TokensResponse = {
  user: User
  authToken: string
  refreshToken: string
  workspaceId?: string
}

type BackendAuthResponse = {
  data: {
    user: {
      id: number
      name: string
      email: string
    }
    authentication_token: string
    refresh_token?: string
    workspaces?: string[]
  }
}

type BackendTokenResponse = {
  data: {
    authentication_token: string
    refresh_token: string
    user: {
      id: number
      name: string
      email: string
    }
    workspaces?: string[]
  }
}

export async function standaloneLogin(
  email: string,
  password: string
): Promise<User> {
  const response = await axios.post<BackendAuthResponse>(
    `${baseURL}/auth/basic/user_sessions`,
    {
      email,
      password
    }
  )
  const accessToken = response.data.data.authentication_token
  const refreshToken = response.data.data.refresh_token
  const workspaceId = response.data.data.workspaces?.at(0)
  const user: User = {
    id: response.data.data.user.id,
    email: response.data.data.user.email,
    name: response.data.data.user.name,
    workspaceId: workspaceId ? parseInt(workspaceId) : undefined
  }
  setAccessToken(accessToken)
  setRefreshToken(refreshToken)
  setWorkspaceId(workspaceId)
  setAuthMethod('basic')
  setUser(user)
  return user
}

export function standaloneDeleteSession(): Promise<void> {
  return axios.delete(`${baseURL}/auth/basic/user_sessions`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_KEY)}`
    }
  })
}

export async function embeddedLogin(
  clientId: string,
  email: string,
  timestamp: string,
  hmac: string
): Promise<User> {
  const response = await axios.post<BackendTokenResponse>(
    `${baseURL}/auth/embedded/user_sessions`,
    {
      client_id: clientId,
      email,
      timestamp,
      hmac
    }
  )
  const accessToken = response.data.data.authentication_token
  const refreshToken = response.data.data.refresh_token
  const workspaceId = response.data.data.workspaces?.at(0)
  const user: User = {
    id: response.data.data.user.id,
    email: response.data.data.user.email,
    name: response.data.data.user.name,
    workspaceId: workspaceId ? parseInt(workspaceId) : undefined
  }
  setAccessToken(accessToken)
  setRefreshToken(refreshToken)
  setWorkspaceId(workspaceId)
  setAuthMethod('embedded')
  setUser(user)
  return user
}

export function isLogged() {
  return Boolean(getUser())
}

export function logout() {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(REFRESH_TOKEN_KEY)
  sessionStorage.removeItem(WORKSPACE_ID_KEY)
  sessionStorage.removeItem(STORED_USER_KEY)
}
