import { ReactElement } from 'react'
import { RelayButton } from '@repo/ui/components'
import { Card } from 'flowbite-react'

export default function Error404Page(): ReactElement {
  const reload = () => (window.location.href = '/')
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-workflow-canvas">
      <Card className="max-w-sm text-gray-800">
        <h5 className="text-md font-bold">Oops, something went wrong!</h5>

        <div className="text-sm">
          Looks like you’ve ended up somewhere unexpected. Let’s take you back
          to the start - just click below to begin again.
        </div>
        <hr />
        <RelayButton color="primary" size="lg" onClick={reload}>
          Start Over
        </RelayButton>
      </Card>
    </div>
  )
}
