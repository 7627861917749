import type { CustomFlowbiteTheme } from 'flowbite-react'

// Part of Forms - Find all default options at https://flowbite-react.com/docs/components/forms#checkbox-theme
export const customCheckboxTheme: CustomFlowbiteTheme['checkbox'] = {
  root: {
    base: 'h-4 w-4 rounded border border-gray-400 bg-gray-100 focus:ring-2 dark:border-gray-600 dark:bg-gray-700',
    color: {
      default:
        'text-primary-700 focus:ring-primary-700 dark:ring-offset-gray-800 dark:focus:ring-primary-700'
    }
  }
}
